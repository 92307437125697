/* Minimal CSS Reset */
* {
  box-sizing: border-box;
}
html {
  font-size: 12px;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
ul {
  list-style: decimal;
  margin-left: 0.5em;
}
img {
  max-width: 100%;
  height: auto;
}
a {
  text-decoration: none;
}
button {
  background-color: #808080;
  color: #fff;
  border-radius: 1em;
  padding: 0.5em;
}
html {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  cursor: default;
}
#menu {
  font-size: 1.2em;
  position: fixed;
  top: 8px;
  left: 8px;
}
#menu h1 {
  margin-bottom: 4px;
}
#menu #record {
  cursor: pointer;
}
#menu #record:hover {
  color: rgba(244,67,54,0.5);
}
#menu #record.active {
  color: #f44336;
}
#menu .song {
  margin-left: 16px;
  cursor: pointer;
}
#menu .song:hover {
  color: rgba(0,150,136,0.5);
}
#menu .song.active {
  color: #009688;
}
#menu #trippy {
  margin-top: 16px;
  cursor: pointer;
}
#menu #trippy.active {
  color: transparent;
  background-clip: text;
  background-image: linear-gradient(to right, #f00, #ffa500, #ff0, #008000, #00f, #4b0082, #ee82ee);
}
#hide {
  position: fixed;
  bottom: 8px;
  left: 8px;
}
#canvas {
  display: block;
  position: fixed;
}
